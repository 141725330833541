<template>
<div class="container">
    <div class="table-responsive tabla-permisos overflow-auto custom-scroll border">
        <table class="table-permisos table-fixed">
            <thead>
                <tr>
                    <th id="subtitle-th">
                       <span id="subtitle" v-if="rutaNameRutas == this.route">Rutas</span>
                    </th>
                    <th v-for="(head,i) in headOriginals" :key="i">
                        <div class="d-middle-center">
                            <el-checkbox-group v-model="checkListHead">
                                <el-checkbox :id="'column_'+head.id" :label="head.id" @change="handleCheckAllMenu(head.id)">{{ '‎' }}</el-checkbox>
                            </el-checkbox-group>
                            <p>{{head.nombre}}</p>
                        </div>
                        <el-popover
                            placement="top-start"
                            title=""
                            width="200"
                            trigger="hover"
                            content="Rutas del módulo"
                            v-if="rutasActive" >
                            <span class="count-rutas" @click="redirect(head.id)" slot="reference"> {{head.rutas_modulo}} </span>
                        </el-popover>
                    </th>
                    <!-- <th v-if="btnAdd.isVisible">
                        <div class="col-auto icon-option my-auto px-1">
                            <el-tooltip :content="`${btnAdd.title}`" effect="light" placement="top" visible-arrow>
                                <i class="icon-plus-circle f-30 cr-pointer" @click="createNew"/>
                            </el-tooltip>
                        </div>
                    </th> -->
                </tr> 
            </thead>
            <tbody v-for="(rolArea,i) in rowsTable" :key="i">
                <tr>
                    <td class="title-table" >{{rolArea.nombre}}</td>
					<td :colspan="`${colspanCalculate - 1}`" style="background:#dddddd"></td>
                </tr>
                <tr v-for="(roles,i) in rolArea.roles" :key="i">
                    <!-- {{ roles.nombre }} -->
                    <td class="subtitle-table">
                        <div class="d-middle">
                            <el-checkbox-group v-model="checkListRol">
                                <el-checkbox :label="roles.id" :id="'row_'+roles.id" @change="handleCheckAllRol(roles.id)" >{{ '‎' }}</el-checkbox>
                            </el-checkbox-group>
                            <p>{{ roles.nombre }}</p>
                        </div>
                    </td>
                    <td v-for="(head,i) in headOriginals" :key="i">
                        <input type="checkbox" id="jack" :value="roles.id+'-'+head.id" v-model="checkListPermiso" @click="guardarPermiso($event)"> 
                    </td>
                </tr>
            </tbody>
        </table>
    </div>   
    <!-- partials -->
    <!-- <modal-crear-new ref="createNew" :type='btnAdd.type' @newHeaders="changeHeaders($event)"/>      -->
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PermisoFactory } from '~/domain/entities/configurar/permisos/factories/PermisoFactory'

export default {
    props: {
        heads: {
            type: Array,
            default: []
        },
        rutasActive: {
            type: Boolean,
            default: false
        },
        // btnAdd: {
        //     type: Object,
        //     default: {
        //         isVisible: false,
        //         title: "",
        //         type: null
        //     }
        // }
    },
    components:{
        modalCrearNew: () => import('./../partials/modalCrearNew.vue'),        
    },
    data() {
        return {
            checkListPermiso: [],            
            checkListHead: [],            
            checkListRol: [],    
            countRows: 0,  
            countColumns: 0,   
            rutaNameAccion: 'configurar.permisos.acciones.menu.grupo', 
            rutaNameRutas: 'configurar.permisos.rutas.detalle.grupo', 
            rutaPrincipalRutas: 'configurar.permisos.rutas',
            rowsTable: [], 
            permisoData: PermisoFactory.instantiateEmpty(), 
            headOriginals: [],
            valueDisabled:false
        };
    },
    computed: {
        ...mapGetters({
            RolesAreas: 'configurar/permisos/roles_areas/rolesAreas',
            PermisosAcciones: 'configurar/permisos/acciones/permisosAcciones',
            menu: 'configurar/permisos/menus/menus',
            user_data: 'auth/user',
        }),
        rolesAreas: {
           get(){
             return this.RolesAreas
           },
           set(newName){
            //  return newName
           } 
        },
        permisosAcciones: {
           get(){
             return this.PermisosAcciones
           },
           set(newName){
            //  return newName
           } 
        },
        id(){ 
            return this.$route.params.id
        },
        colspanCalculate(){
            this.countColumns = this.headOriginals.length;
            return this.headOriginals.length + 1
            // return this.btnAdd.isVisible ? this.headOriginals.length + 2 : this.headOriginals.length + 1
        },
        generateRows(){
            //this.rowsTable = this.$route.name == this.rutaNameAccion ? this.permisosAcciones : this.rolesAreas ;
        },
        tab(){ 
            return this.$route.params.tab
        },
        headsTable(){
            var array = [];
            setTimeout(() => 
                this.heads.forEach(element => {
                    if(element.id_menu_superior == null){
                        array.push(element)
                    } 
            }), 350);
                
            this.headOriginals = array
            return array;
        },
        route(){
            return this.$route.name
        }
    },
    watch:{
        tab(){  
            setTimeout(() => this.validateAll(), 400);
            var array = [];
            setTimeout(() => 
                this.heads.forEach(element => {
                    if(element.id_menu_superior == null){
                        array.push(element)
                    } 
            }), 350);                
            this.headOriginals = array
        },
    },  
    async created(){ 
        const ruta = this.route;
        if(ruta == "configurar.permisos.rutas"){
            await this.getRolesAreas();
        }else if(ruta == this.rutaNameRutas){
            await this.getRolesAreas();
            await this.getPermisosAcciones();
        }else{
            await this.getPermisosAcciones();
        }
        setTimeout(() => this.rowsTable = ruta == this.rutaNameAccion ? this.permisosAcciones : this.rolesAreas, 600);
        var array = [];
        setTimeout(() => 
        this.heads.forEach(element => {
            if(element.id_menu_superior == null){
                if (this.user_data.mostrar_configuracion == 1 && this.user_data.super_admin == 0) {
                    if (this.$route.name == 'configurar.permisos.rutas.detalle.grupo' || this.$route.name == 'configurar.permisos.acciones.menu.grupo') {
                        array.push(element)
                    }else{
                        const exists = this.user_data?.permiso_configuracion.find(e => `${e.nombre}`.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim() == `${element.nombre}`.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim())
                        if(exists) array.push(element)
                    }
                }else{
                    array.push(element)
                }
                }
        }), 350);
        this.headOriginals = array     
        setTimeout(() => this.getPermisos(), 700);   
    },
    methods: {
        redirect(id){
            const idTab = this.getFirstSubMenu(id)
            this.$router.push({ name: 'configurar.permisos.rutas.detalle', params: { id, tab: idTab }})
        },
        ...mapActions({
            getRolesAreas: 'configurar/permisos/roles_areas/Action_get_rolesAreas',
            getPermisosAcciones: 'configurar/permisos/acciones/Action_get_permisosAcciones',  
            crearPermisoAccion: 'configurar/permisos/acciones/Action_create_permiso', 
            crearPermisoRuta: 'configurar/permisos/acciones/Action_create_permiso_ruta', 
            addPermisoRoles: 'configurar/permisos/acciones/Action_add_permiso_roles',  
            addRolPermisos: 'configurar/permisos/acciones/Action_add_rol_permisos',  
            addRolPermisosRuta: 'configurar/permisos/acciones/Action_add_rol_permisos_ruta', 
            addRolPermisosRutaRoles: 'configurar/permisos/acciones/Action_add_rol_permisos_ruta_roles', 
            getMenus: 'configurar/permisos/menus/Action_get_menus',
            addPermisosRutaGenerales: 'configurar/permisos/acciones/Action_add_permisos_ruta_generales', 
        }), 
        createNew(){
            this.$refs.createNew.toggle()
        },
        getPermisos() {
            this.countRows = 0;
            const parameter = this.route == this.rutaNameRutas ? true : false ;
            if(this.$route.name != 'configurar.permisos.rutas'){  
                this.permisosAcciones.forEach(element => {                        
                    element.roles.forEach(value => { 
                        this.countRows += 1;
                        if(parameter){
                            value.id_menus_permisos_roles.forEach(menu => {
                                this.checkListPermiso.push(value.id+'-'+menu);
                            })
                        }else{
                            value.id_permisos_roles.forEach(menu => {
                                this.checkListPermiso.push(value.id+'-'+menu);
                            })
                        }
                    });
                }); 
            }else{
                //traer todos los permisos principales
                this.rolesAreas.forEach(element => {
                    element.roles.forEach(e => {
                        this.countRows += 1;
                        e.id_menus.forEach(val => {
                            this.checkListPermiso.push(e.id+'-'+val);
                        })
                    })
                })
            }
            setTimeout(() => this.validateAll(), 400);            
        },
        async handleCheckAllMenu(val) {
            this.valueDisabled = true
            var value = this.checkListHead.includes(val);
            value? await this.selectAll(val): await this.quitSelectAll(val);
            this.valueDisabled = false

        },
        async handleCheckAllRol(val){ 
            this.valueDisabled = true
            var value = this.checkListRol.includes(val);
            value? await this.selectAllRow(val): await this.quitSelectAllRow(val);
            this.valueDisabled = false
        },
        quitSelectAll(val){   
            var eliminarRolesPermiso = {
                id_rol: [],
                id_permiso: ''
            }            
            this.rowsTable.forEach(element => {
                element.roles.forEach(value => { 
                    var permiso = value.id+'-'+val;   
                    eliminarRolesPermiso.id_permiso = val
                    eliminarRolesPermiso.id_rol.push(value.id)
                    if(this.rutaNameRutas == this.$route.name) eliminarRolesPermiso.id_menu = this.tab;
                    var filtered = this.checkListPermiso.filter((item) => item !== permiso) 
                    this.checkListPermiso = filtered;   

                });
            }); 
            if(this.rutaNameAccion == this.$route.name) this.agregatePermisoRoles(eliminarRolesPermiso);
            if(this.rutaNameRutas == this.$route.name) this.agregatePermisoRolesRutas(eliminarRolesPermiso);
            if(this.rutaPrincipalRutas == this.$route.name) this.agregateAllPermisosRuta(eliminarRolesPermiso, false);   
        },
        async selectAll(val) {  
            var agregarRolesPermiso = {
                id_rol: [],
                id_permiso: ''
            }              
            this.rowsTable.forEach(element => {
                element.roles.forEach(value => { 
                    var permiso = value.id+'-'+val;   
                    const filter = this.checkListPermiso.filter((item) => item == permiso);    
                    if(filter.length == 0){
                        agregarRolesPermiso.id_permiso = val  
                        agregarRolesPermiso.id_rol.push(value.id)
                        if(this.rutaNameRutas == this.$route.name) agregarRolesPermiso.id_menu = this.tab;
                        this.checkListPermiso.push(permiso);
                    }       
                });
            });
            if(this.rutaNameAccion == this.$route.name) await this.agregatePermisoRoles(agregarRolesPermiso);
            if(this.rutaNameRutas == this.$route.name) await this.agregatePermisoRolesRutas(agregarRolesPermiso);       
            if(this.rutaPrincipalRutas == this.$route.name) await this.agregateAllPermisosRuta(agregarRolesPermiso, true);       
        },
        async quitSelectAllRow(val){   
            var eliminarPermisosRol = {
                id_rol: '',
                id_permiso: []
            }                            
            this.headOriginals.forEach(element => {      
                var permiso = val+'-'+element.id;    
                eliminarPermisosRol.id_permiso.push(element.id)
                eliminarPermisosRol.id_rol = val 
                if(this.rutaNameRutas == this.$route.name) eliminarPermisosRol.id_menu = this.tab;
                var filtered = this.checkListPermiso.filter((item) => item !== permiso) 
                this.checkListPermiso = filtered;                 
            });
            if(this.rutaNameAccion == this.$route.name) await this.agregateRolPermisos(eliminarPermisosRol);
            if(this.rutaNameRutas == this.$route.name) await this.agregateRolPermisosRuta(eliminarPermisosRol);
            if(this.rutaPrincipalRutas == this.$route.name) await this.agregateAllPermisosRutaRol(eliminarPermisosRol, false);   
        },
        async selectAllRow(val) {  //Selecciona todos los checkbos del rol especifico
            var agregarPermisosRol = {
                id_rol: '',
                id_permiso: []
            }     
            this.checkListRol.forEach(data => {                     
                this.headOriginals.forEach(element => {      
                    var permiso = data+'-'+element.id;    
                    const filter = this.checkListPermiso.filter((item) => item == permiso); //validamos si el permiso ya esta marcado   
                    if(filter.length == 0){
                        this.checkListPermiso.push(permiso);
                        agregarPermisosRol.id_permiso.push(element.id)
                        agregarPermisosRol.id_rol = data  
                        if(this.rutaNameRutas == this.$route.name) agregarPermisosRol.id_menu = this.tab;
                    }
                });
            });
            if(this.rutaNameAccion == this.$route.name) await this.agregateRolPermisos(agregarPermisosRol);
            if(this.rutaNameRutas == this.$route.name) await this.agregateRolPermisosRuta(agregarPermisosRol);  
            if(this.rutaPrincipalRutas == this.$route.name) await this.agregateAllPermisosRutaRol(agregarPermisosRol, true); 
        },
        validateAll(){  
            this.checkListRol = [];
            const headsInTable = this.headOriginals == null ? this.heads : this.headOriginals;
            const roles = [];
            const headers = [];
            const resultado1 = {}
            const resultado2 = {}
            var parsedobj = JSON.parse(JSON.stringify(this.heads))
            this.checkListPermiso.forEach(element => { //contar numero de checks marcados para cada rol y encabezado
                const myArray = element.split("-"); 
                const headerInPage =  parsedobj.findIndex(el => el.id == myArray[1]*1); //validamos si el header se muestra en pantalla
                if(headerInPage >= 0){
                    roles.push(myArray[0]);
                    headers.push(myArray[1]);
                }
            }) 
            roles.forEach(el => {(resultado1[el] = resultado1[el] + 1 || 1)}) //Agrupar roles: #permisos
            headers.forEach(el => (resultado2[el] = resultado2[el] + 1 || 1)) //Agrupar encabezado: #de permisos       
            Object.entries(resultado1).forEach(([key, value]) => {  //Marcamos los roles que tienen todos los permisos marcados
                if(value == parsedobj.length){
                    this.checkListRol.push(key*1);
                }             
            });
            Object.entries(resultado2).forEach(([key, value]) => { //Marcamos los encabezados que tienen todos los permisos marcados
                if(value == this.countRows){
                    this.checkListHead.push(key*1);
                }
            });
        },
        async guardarPermiso(e){ 
            const allPermiso = e.target.value.split("-");
            let idRol = allPermiso[0];
            let idPermiso = allPermiso[1];
            this.permisoData = {
                id_rol: idRol,
                id_permiso: idPermiso
            };
            this.permisoRutaData = {
                id_menu: this.tab,
                id_rol: idRol,
                id_permiso: idPermiso
            }
            this.permisoRutaPrincipal = {
                id_rol: allPermiso[0],
                id_menu: allPermiso[1],
            }
            if(this.$route.name == this.rutaNameAccion){ 
                await this.crearPermisoAccion(this.permisoData)
            }
            if(this.$route.name == this.rutaNameRutas){
                await this.crearPermisoRuta(this.permisoRutaData)
            }
            if(this.$route.name == this.rutaPrincipalRutas){
                const splitPermiso = e.target.value.split("-");
                const permisoGeneral = {
                    guardar: e.target.checked,
                    permisos: [
                        {
                            id_menu: splitPermiso[1],
                            id_rol: splitPermiso[0]
                        }
                    ]
                }
                await this.addPermisosRutaGenerales(permisoGeneral)
            }
            setTimeout(() => this.validateAll(), 400);
        }, 
        async agregatePermisoRoles(eliminarRolesPermiso){
            if(this.$route.name == this.rutaNameAccion){ 
                await this.addPermisoRoles(eliminarRolesPermiso)
            }
        },
        async agregatePermisoRolesRutas(eliminarRolesPermiso){
            if(this.$route.name == this.rutaNameRutas){ 
                await this.addRolPermisosRutaRoles(eliminarRolesPermiso)
            }
        },
        async agregateRolPermisos(eliminarRolesPermiso){
            if(this.$route.name == this.rutaNameAccion){ 
                await this.addRolPermisos(eliminarRolesPermiso)
            }
        }, 
        async agregateRolPermisosRuta(eliminarRolesPermiso){
            if(this.$route.name == this.rutaNameRutas){ 
                await this.addRolPermisosRuta(eliminarRolesPermiso)
            }
        },
        async agregateAllPermisosRuta(eliminarRolesPermiso, checkValue){
            const addAllPermisos = [];
            addAllPermisos.push({
                guardar: checkValue,
                permisos: []
            })
            eliminarRolesPermiso.id_rol.forEach(element => {
                addAllPermisos[0].permisos.push({
                    id_menu: eliminarRolesPermiso.id_permiso,
                    id_rol: element
                });
            });
            if(this.$route.name == this.rutaPrincipalRutas){  
                await this.addPermisosRutaGenerales(addAllPermisos[0])
            }
        },
        async agregateAllPermisosRutaRol(eliminarRolesPermiso, checkValue){
            const addAllPermisos = [];
            addAllPermisos.push({
                guardar: checkValue,
                permisos: []
            })
            eliminarRolesPermiso.id_permiso.forEach(element => {
                addAllPermisos[0].permisos.push({
                    id_menu: element,
                    id_rol: eliminarRolesPermiso.id_rol
                });
            });
            if(this.$route.name == this.rutaPrincipalRutas){  
                await this.addPermisosRutaGenerales(addAllPermisos[0])
            }
        },
        changeHeaders(value)
        {
            this.headOriginals = value;
        }, 
        getFirstSubMenu(id) { 
            const first = this.heads.find(el => el.id_menu_superior == id)
            return first == null ? 0 : first.id             
        }
    }

}
</script>

<style lang="scss" scoped>

.table-permisos {
    border-collapse: collapse;
    width: 100%;  
    text-align: center;  
    font-size: 14px;
    overflow-y: auto;
    height: 502px;
}
td, th {
    border-top: 0;
    border-right: 1px solid #dddddd;
    border-bottom: 0;
    border-left: 0;
    text-align: left;
    padding: 8px;
    text-align: center;
    font-weight: normal;
    color: var(--text-general);
}
.title-table {
    background-color: #dddddd;
    text-align: left;
    font-weight: 600;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
}
#subtitle-th {
    text-align: right;
	text-align: left;
	position: -webkit-sticky;
	/* for Safari */
	position: sticky;
	left: 0;
	background: #FFF;
	z-index: 8;
}
#subtitle {
    color: var(--color-general);
    font-style: italic;
    margin-right: 15px;
}
.subtitle-table {
    text-align: left;
	position: -webkit-sticky;
	/* for Safari */
	position: sticky;
	left: 0;
	background: #FFF;
}
.count-rutas {
    background-color: #dddddd;
    padding: 3px 10px 3px 10px;
    color: var(--text-general);
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
}
.tabla-permisos{
	//max-width: calc(100vw - 142px);
    max-height: calc(100vh - 355px);
    overflow: scroll;
    position: relative;
	thead tr th{
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		background: #fff;
		color: #6b778c;
		z-index: 2;
	}
}
p{
    cursor: default;
}
</style>